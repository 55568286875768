import React, { useEffect, useState } from "react";
import { AnchorButton, Button, Icon, Intent, Menu, MenuItem, Spinner } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { Emerald, Release } from "@prisma/client";
import fuzzy from "fuzzy";

import Editor, {
	useEditor,
	getDocJson,
	clearEditor,
	docIsEmpty,
	getDocString,
} from "components/Editor/Editor";
import { useLocationContext, useQuickAddSyncContext } from "utils/client/hooks";

import styles from "./QuickRelease.module.scss";
import { getMinorIncrement } from "utils/shared/versions";

type PopulatedEmerald = Emerald & { releases: Release[] };
type Props = {};

const QuickRelease: React.FC<Props> = ({}) => {
	const placeholderText = "Quick draft a minor release...";
	const locationData = useLocationContext();
	const quickAddSync = useQuickAddSyncContext();
	const [editorState, setEditorState] = useEditor(null, placeholderText);
	const [newDataAt, setNewDataAt] = useState(Date.now());
	const [successTarget, setSuccessTarget] = useState({ version: "", emeraldSlug: "" });
	const emptyDoc = docIsEmpty(editorState);
	const [releaseLoading, setReleaseLoading] = useState(false);
	const [availableEmeralds, setAvailableEmeralds] = useState<PopulatedEmerald[]>([]);
	const [gettingEmeralds, setGettingEmeralds] = useState(false);
	const [activeEmeraldId, setActiveEmeraldId] = useState<string>("");
	const activeEmerald = availableEmeralds.find((emerald) => {
		return emerald.id === activeEmeraldId;
	});
	const activeVersion = activeEmerald?.releases.length
		? activeEmerald.releases[0].version
		: "0.0";
	useEffect(() => {
		const fetchEmeralds = async () => {
			setGettingEmeralds(true);
			const response = await fetch("/api/quickRelease", {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			});
			const returnedEmeralds: PopulatedEmerald[] = await response.json();
			setAvailableEmeralds(returnedEmeralds);
			const emeraldOnPage = returnedEmeralds.find((emerald) => {
				return emerald.slug === locationData.slug;
			});
			if (emeraldOnPage) {
				setActiveEmeraldId(emeraldOnPage.id);
			}

			setGettingEmeralds(false);
		};
		fetchEmeralds();
	}, [newDataAt]);

	const handleSubmit = async () => {
		setReleaseLoading(true);
		setSuccessTarget({ version: "", emeraldSlug: "" });
		try {
			const response = activeEmerald
				? null
				: await fetch("/api/emerald", {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							title: `${getDocString(editorState).substring(0, 20)}...`,
						}),
				  });

			const { data: newEmerald } = !response
				? { data: activeEmerald }
				: await response.json();

			const nextVersion = getMinorIncrement(activeVersion);
			const releaseResponse = await fetch("/api/release", {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					text: getDocJson(editorState),
					version: nextVersion,
					emeraldId: newEmerald.id,
				}),
			});
			if (!releaseResponse.ok) {
				throw new Error("Duplicate version number");
			}
			await quickAddSync.current(newEmerald.id);
			clearEditor(setEditorState, placeholderText);
			setNewDataAt(Date.now());
			setSuccessTarget({ version: nextVersion, emeraldSlug: newEmerald.slug });
			// setActiveEmeraldId(undefined);
		} catch (error) {
			console.log(error);
		}

		setReleaseLoading(false);
	};

	return (
		<div className={styles.quickRelease}>
			<div className={styles.inputWrapper}>
				<Editor state={editorState} setState={setEditorState} editable={true} />
				<div className={styles.releaseButtons}>
					<div className={styles.buttonLeft}>
						{successTarget.version && (
							<AnchorButton
								href={`/e/${successTarget.emeraldSlug}#v${successTarget.version}`}
								icon="tick"
								intent={Intent.SUCCESS}
								minimal
								small
							>
								Success! Go to v{successTarget.version}
							</AnchorButton>
						)}
					</div>
					<div className={styles.buttonRight}>
						<div className={styles.selectButton}>
							<Select
								items={availableEmeralds}
								itemPredicate={(query: string, item: PopulatedEmerald) => {
									return fuzzy.test(query, item.title);
								}}
								itemRenderer={(
									item: PopulatedEmerald,
									{ handleClick, modifiers }
								) => {
									if (!modifiers.matchesPredicate) {
										return null;
									}
									const isSelected = activeEmerald?.id === item.id;
									return (
										<MenuItem
											className={isSelected ? "" : styles.menuItem}
											active={modifiers.active}
											key={item.id}
											label={
												item.releases.length
													? item.releases[0].version
													: "0.0"
											}
											onClick={handleClick}
											text={item.title}
											icon={isSelected ? "tick" : undefined}
										/>
									);
								}}
								itemListRenderer={({ items, itemsParentRef, renderItem }) => {
									const renderedItems = items
										.sort((foo, bar) => {
											if (foo.title > bar.title) {
												return 1;
											}
											if (foo.title < bar.title) {
												return -1;
											}
											return 0;
										})
										.map(renderItem)
										.filter((item) => item != null);
									if (gettingEmeralds) {
										return (
											<div style={{ padding: "10px" }}>
												<Spinner size={25} />
											</div>
										);
									}
									return (
										<Menu ulRef={itemsParentRef} className={styles.selectMenu}>
											{activeEmerald && (
												<MenuItem
													className={styles.menuItem}
													text="Clear Selection"
													onClick={() => {
														setActiveEmeraldId("");
													}}
													labelElement={<Icon icon="cross" />}
												/>
											)}
											{renderedItems}
										</Menu>
									);
								}}
								noResults={<MenuItem disabled={true} text="No results." />}
								onItemSelect={(item) => {
									setActiveEmeraldId(item.id);
								}}
								popoverProps={{
									minimal: true,
									modifiers: {
										preventOverflow: { enabled: false },
										flip: { enabled: false },
									},
								}}
							>
								<Button
									text={
										activeEmerald
											? `Add to: ${activeEmerald.title}`
											: "Select Emerald to Add to"
									}
									rightIcon="caret-down"
									small
									minimal
								/>
							</Select>
						</div>

						<Button
							className={styles.submitButton}
							text={
								activeEmerald
									? `Publish v${getMinorIncrement(activeVersion)}`
									: "Create New Emerald"
							}
							onClick={handleSubmit}
							loading={releaseLoading}
							small
							disabled={emptyDoc}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default QuickRelease;
