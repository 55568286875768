import { Plugin } from "prosemirror-state";
import { Fragment, Slice, Node } from "prosemirror-model";
import isURL from "is-url";

export default () => {
	return new Plugin({
		props: {
			handlePaste: (view, event) => {
				if (
					event.clipboardData &&
					isURL(event.clipboardData.getData("Text")) &&
					view.state.selection.from !== view.state.selection.to
				) {
					view.dispatch(
						view.state.tr.addMark(
							view.state.selection.from,
							view.state.selection.to,
							view.state.schema.marks.link.create({
								href: event.clipboardData.getData("Text"),
							})
						)
					);

					return true;
				}
				return false;
			},
			transformPasted: (slice: Slice) => {
				return new Slice(linkify(slice.content), slice.openStart, slice.openEnd);
			},
		},
	});
};

const HTTP_LINK_REGEX =
	/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g;
let linkify = function (fragment: Fragment): Fragment {
	var linkified: Node[] = [];
	fragment.forEach(function (child: Node) {
		if (child.isText) {
			const text = child.text as string;
			var pos = 0,
				match;

			while ((match = HTTP_LINK_REGEX.exec(text))) {
				var start = match.index;
				var end = start + match[0].length;
				var link = child.type.schema.marks["link"];

				// simply copy across the text from before the match
				if (start > 0) {
					linkified.push(child.cut(pos, start));
				}

				const urlText = text.slice(start, end);
				linkified.push(
					child.cut(start, end).mark(link.create({ href: urlText }).addToSet(child.marks))
				);
				pos = end;
			}

			// copy over whatever is left
			if (pos < text.length) {
				linkified.push(child.cut(pos));
			}
		} else {
			linkified.push(child.copy(linkify(child.content)));
		}
	});

	return Fragment.fromArray(linkified);
};
