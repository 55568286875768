import React from "react";
import Image from "next/image";
import classNames from "classnames";
import styles from "./Avatar.module.scss";

type Props = {
	name?: string;
	src?: string | null;
	size?: number;
	className?: string;
	isCollaborator?: boolean;
	isReleaser?: boolean;
};

const Avatar: React.FC<Props> = function ({
	name = "?",
	src,
	size = 32,
	className = "",
	isCollaborator = false,
	isReleaser = false,
}) {
	const inlineStyle = {
		width: `${size}px`,
		height: `${size}px`,
		minWidth: `${size}px`,
		minHeight: `${size}px`,
		fontSize: Math.floor(size / 2),
	};
	return (
		<div className={classNames(styles.avatar, className)} style={inlineStyle}>
			{!src && name && (name || "?").charAt(0)}
			{!!src && <Image className={styles.round} height={size} width={size} src={src} />}
			{isCollaborator && <img className={styles.star} src="/star.svg" />}
			{isReleaser && <img className={styles.star} src="/starFilled.svg" />}
		</div>
	);
};

export default Avatar;
