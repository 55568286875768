import React, { useState } from "react";
import { AnchorButton, Button, Dialog, Icon, Intent } from "@blueprintjs/core";

import { useLoginContext } from "utils/client/hooks";
import { HeaderProfileButton, QuickRelease } from "components";
import styles from "./Header.module.scss";
import { getDailyEventCount } from "utils/shared/events";

const Header = () => {
	const loginData = useLoginContext();
	const activityCount = getDailyEventCount(loginData?.events);
	const [quickAddOpen, setQuickAddOpen] = useState(false);
	return (
		<nav className={styles.header}>
			<div className={styles.content}>
				<AnchorButton
					className={styles.title}
					icon={<img src="/logo.png" alt="Emerald logo" />}
					// text="Emerald"
					href="/"
					minimal
				/>
				<div>
					{!!loginData && (
						<Button
							text="Quick Add"
							small
							intent={Intent.SUCCESS}
							onClick={() => {
								setQuickAddOpen(true);
							}}
						/>
					)}
					{/* <AnchorButton
						className={styles.discover}
						text="About"
						href="/about"
						large
						minimal
					/> */}

					{!loginData && (
						<React.Fragment>
							<AnchorButton text="Login" href="/login" large minimal />
							<AnchorButton text="Sign up" href="/signup" large outlined />
						</React.Fragment>
					)}
					{!!loginData && (
						<React.Fragment>
							<AnchorButton
								className={styles.activityIconButton}
								minimal
								large
								icon={
									<span className={styles.activityIconWrapper}>
										<Icon icon="pulse" />
										{!!activityCount && (
											<div className={styles.activityCount}>
												{activityCount}
											</div>
										)}
									</span>
								}
								href="/activity"
							/>
							<HeaderProfileButton />
						</React.Fragment>
					)}
				</div>
			</div>
			<Dialog
				isOpen={quickAddOpen}
				onClose={() => {
					setQuickAddOpen(false);
				}}
				className={styles.dialog}
			>
				<QuickRelease />
			</Dialog>
		</nav>
	);
};

export default Header;
