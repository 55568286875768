import React from "react";
import { AnchorButton } from "@blueprintjs/core";

import { useLoginContext } from "utils/client/hooks";

import styles from "./PaymentBanner.module.scss";

const Header = () => {
	const loginData = useLoginContext();
	return null;
	return (
		<div className={styles.banner}>
			<div className={styles.content}>
				We see you're actively using Emerald 💚 Please upgrade to Premium to continue.
				<AnchorButton
					className={styles.button}
					outlined
					text="Upgrade Account"
					href={`/u/${loginData?.slug}/settings#plans`}
				/>
			</div>
		</div>
	);
};

export default Header;
