import { useEffect } from "react";
import { FocusStyleManager } from "@blueprintjs/core";
import { setEnv } from "utils/client/environment";
import { createBrowserSupabase, supabase } from "utils/client/supabase";

export const initClient = (
	vercelEnv: string,
	supabaseUrl: string,
	supabaseKey: string,
	query: { signupCompleted?: string; inviteDest?: string }
) => {
	return useEffect(() => {
		FocusStyleManager.onlyShowFocusOnTabs();
		setEnv(vercelEnv);
		createBrowserSupabase(supabaseUrl, supabaseKey);

		const initSession = supabase.auth.session();
		if (initSession) {
			supabase.auth.signIn({
				refreshToken: initSession.refresh_token,
			});
		}

		supabase.auth.onAuthStateChange(async (event, session) => {
			if (event === "SIGNED_IN" && query.signupCompleted) {
				await fetch("/api/token", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ token: session?.access_token }),
				});
				window.location.href = "/";
			}
			if (event === "SIGNED_IN" && query.inviteDest) {
				/* The reason we're not just reusing something like */
				/* ?signupCompleted=true&redirect=blah is because */
				/* supabase seems to only pass on the first query param */
				/* after an authentication. We use ?inviteDest instead of */
				/* ?redirect= because we don't want the login page (which uses) */
				/* ?redirect to automatically redirect if someone is logged in */
				await fetch("/api/token", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ token: session?.access_token }),
				});
				window.location.href = query.inviteDest;
			}

			if (event === "PASSWORD_RECOVERY") {
				await fetch("/api/token", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ token: session?.access_token }),
				});
				window.location.href = `/password-reset/${session?.access_token}`;
			}

			if (event === "TOKEN_REFRESHED") {
				/* Sync the emerald token when a supabase token is refreshed */
				/* If it's restoring an expired token (i.e. initSession is null) */
				/* Refresh page to show logged in state */
				await fetch("/api/token", {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({ token: session?.access_token }),
				});
				if (!initSession) {
					window.location.href = "/";
				}
			}
		});
	}, []);
};
