import { Plugin } from "prosemirror-state";
import { DecorationSet, Decoration } from "prosemirror-view";

import { docIsEmpty } from "./Editor";

export default (placeholder: string) => {
	return new Plugin({
		props: {
			decorations: (state) => {
				if (docIsEmpty(state) && placeholder) {
					const decorations: any[] = [];
					state.doc.descendants((node, pos) => {
						const placeholderText = placeholder;
						if (node.type.isBlock && node.childCount === 0) {
							decorations.push(
								Decoration.node(pos, pos + node.nodeSize, {
									class: "prosemirror-placeholder",
									"data-content": placeholderText,
								})
							);
						}
					});
					return DecorationSet.create(state.doc, decorations);
				}
				return null;
			},
		},
	});
};
