import { AnchorButton } from "@blueprintjs/core";
import React from "react";

import styles from "./Footer.module.scss";

const Footer = function () {
	return (
		<div className={styles.footer}>
			{/* <a href="/about">About Emerald</a> */}
			<AnchorButton minimal href="/about">
				About Emerald
			</AnchorButton>
			<div className={styles.min}>
				<AnchorButton minimal href="/pricing">
					Pricing
				</AnchorButton>
				<AnchorButton minimal href="/tos">
					Terms of Service
				</AnchorButton>
				<AnchorButton minimal href="/privacy">
					Privacy
				</AnchorButton>
			</div>
		</div>
	);
};

export default Footer;
