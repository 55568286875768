import { Release } from "@prisma/client";

export const getMaxReleaseVersion = (releases: Release[]) => {
	return releases.reduce((prev, curr) => {
		return getLargerVersion(curr.version, prev);
	}, "0.0");
};

export const isMajorVersion = (version: string) => {
	const [_major, minor] = version.split(".");
	return minor === "0";
};
export const getMinorIncrement = (currentVersion: string) => {
	const [major, minor] = currentVersion.split(".");
	return `${major}.${Number(minor) + 1}`;
};

export const getMajorIncrement = (currentVersion: string) => {
	const [major] = currentVersion.split(".");
	return `${Number(major) + 1}.0`;
};

export const isSameMajorVersion = (foo: string, bar: string) => {
	const [fooMajor] = foo.split(".");
	const [barMajor] = bar.split(".");
	return fooMajor === barMajor;
};
export const getLargerVersion = (foo: string, bar: string) => {
	const [fooMajor, fooMinor] = foo.split(".");
	const [barMajor, barMinor] = bar.split(".");
	if (Number(fooMajor) > Number(barMajor)) {
		return foo;
	}
	if (Number(fooMajor) < Number(barMajor)) {
		return bar;
	}
	if (Number(fooMinor) > Number(barMinor)) {
		return foo;
	}
	if (Number(fooMinor) < Number(barMinor)) {
		return bar;
	}
	return foo;
};
