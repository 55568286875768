type Event = { createdAt: string };

export const bucketedEventsByDay = (events: Event[] = []) => {
	return events.reduce((prev: any, curr) => {
		const dateKey = getDateKey(curr.createdAt);
		const list = prev[dateKey] || [];
		list.push(curr);
		prev[dateKey] = list;
		return prev;
	}, {});
};

export const getDailyEventCount = (events: Event[] = []) => {
	let count = 0;
	const todayKey = getDateKey(Date.now());
	events.forEach((event) => {
		const dateKey = getDateKey(event.createdAt);
		if (dateKey === todayKey) {
			count += 1;
		}
	});
	return count;
};

const getDateKey = (date: string | number) => {
	const dateString = new Date(date).toLocaleDateString("en-GB", { timeZone: "America/New_York" });
	const splitDate = dateString.split("/");
	return `${splitDate[2]}_${splitDate[1]}_${splitDate[0]}`;
};

// To get timeZone in modern browsers:
// Intl.DateTimeFormat().resolvedOptions().timeZone
