import "prosemirror-view/style/prosemirror.css";
import React, { SetStateAction, Dispatch } from "react";
import { EditorState } from "prosemirror-state";
import { Schema } from "prosemirror-model";
import { schema } from "prosemirror-schema-basic";
import { useProseMirror, ProseMirror } from "use-prosemirror";
import { exampleSetup } from "prosemirror-example-setup";
import { addListNodes } from "prosemirror-schema-list";
import classNames from "classnames";

import buildPlaceholderPlugin from "./placeholder";
import buildLinkifyPlugin from "./linkify";
import styles from "./Editor.module.scss";

const mySchema = new Schema({
	nodes: addListNodes(schema.spec.nodes, "paragraph block*", "block"),
	marks: schema.spec.marks,
});

const buildEditorOptions = (placeholder = "") => {
	return {
		schema: mySchema,
		plugins: [
			...exampleSetup({ schema: mySchema, menuBar: false }),
			buildPlaceholderPlugin(placeholder),
			buildLinkifyPlugin(),
		],
	};
};

export const setTemplate1 = function (setEditorState: Dispatch<SetStateAction<EditorState>>) {
	const newState = EditorState.create({
		...buildEditorOptions(),
		doc: mySchema.nodeFromJSON({
			type: "doc",
			content: [
				{
					type: "heading",
					attrs: { level: 1 },
					content: [{ type: "text", text: "Describe your Idea" }],
				},
				{ type: "paragraph" },
				{
					type: "heading",
					attrs: { level: 1 },
					content: [{ type: "text", text: "Describe your audience" }],
				},
				{ type: "paragraph" },
				{
					type: "heading",
					attrs: { level: 1 },
					content: [{ type: "text", text: "Describe your model" }],
				},
				{ type: "paragraph" },
			],
		}),
	});
	setEditorState(newState);
};
export const setTemplate2 = function (setEditorState: Dispatch<SetStateAction<EditorState>>) {
	const newState = EditorState.create({
		...buildEditorOptions(),
		doc: mySchema.nodeFromJSON({
			type: "doc",
			content: [
				{
					type: "heading",
					attrs: { level: 1 },
					content: [{ type: "text", text: "Project Name" }],
				},
				{ type: "paragraph" },
				{
					type: "heading",
					attrs: { level: 1 },
					content: [{ type: "text", text: "Who is responsible?" }],
				},
				{ type: "paragraph" },
				{
					type: "heading",
					attrs: { level: 1 },
					content: [{ type: "text", text: "What is the plan?" }],
				},
				{ type: "paragraph" },
			],
		}),
	});
	setEditorState(newState);
};
export const clearEditor = function (
	setEditorState: Dispatch<SetStateAction<EditorState>>,
	placeholder?: string
) {
	const clearState = EditorState.create(buildEditorOptions(placeholder));
	setEditorState(clearState);
};

export const docIsEmpty = (editorState: EditorState) => {
	const doc = editorState.doc;
	if (doc.childCount === 0) {
		return true;
	}
	if (doc.childCount === 1) {
		const { isTextblock, content, attrs } = doc.firstChild!;
		return isTextblock && content.size === 0 && !attrs.textAlign;
	}
	return false;
};

export const useEditor = function (
	docJson?: any,
	placeholder?: string
): [EditorState, Dispatch<SetStateAction<EditorState>>] {
	const doc = docJson ? mySchema.nodeFromJSON(docJson) : undefined;
	const [state, setState] = useProseMirror({
		...buildEditorOptions(placeholder),
		doc: doc,
	});
	return [state, setState];
};
export const getDocJson = function (state: EditorState): object {
	return state.doc.toJSON();
};
export const getDocString = function (state: EditorState): string {
	return state.doc.textContent;
};

export const getDocStringFromJson = function (docJson: any) {
	const doc = mySchema.nodeFromJSON(docJson);
	return doc.textContent;
};

type Props = {
	state: EditorState;
	setState: Dispatch<SetStateAction<EditorState>>;
	editable?: boolean;
	smallFont?: boolean;
};

const Editor: React.FC<Props> = function ({ state, setState, editable = true, smallFont = false }) {
	return (
		<ProseMirror
			state={state}
			onChange={setState}
			editable={() => editable}
			className={classNames(
				styles.editor,
				editable ? "" : styles.static,
				smallFont && styles.smallFont
			)}
		/>
	);
};

export default Editor;
