import React, { useContext, MutableRefObject } from "react";
import { LocalUserData, LocationData } from "utils/shared/types";

export const LoginContext = React.createContext<LocalUserData>(undefined);
export const useLoginContext = (): LocalUserData => useContext(LoginContext);

export const LocationContext = React.createContext<LocationData>({});
export const useLocationContext = (): LocationData => useContext(LocationContext);

type QuickAddSync = MutableRefObject<(emeraldId: string) => void>;
export const QuickAddSyncContext = React.createContext<QuickAddSync>({ current: () => {} });
export const useQuickAddSyncContext = (): QuickAddSync => useContext(QuickAddSyncContext);
